/* eslint-disable i18n/no-russian-character */
import authLogo from './assets/authLogo.png'
import companyLogo from './assets/companyLogo.svg'
import logoFull from './assets/logo-full_l.svg'
import logoFull_M from './assets/logo-full_m.svg'
import logo from './assets/logo.svg'
import './palette.css'

window.PLATFORM = {
  passwordMinLength: 10,
  PlatformName: 'Lynda',
  PlatformNameRu: 'Линда',
  announcerDefaultLink: 'https://help.lynda.ru',
  knowledgeBase: 'https://help.lynda.ru',
  emailDomain: 'lynda.ru',
  hubSpotChatPath: 'https://js-eu1.hs-scripts.com/24969710.js',
  hubSpotContactSalesPath: 'https://meetings-eu1.hubspot.com/leenda-/demo-leenda?embed=true',
  authBG: 'linear-gradient(114deg, #0a8571 0%, #04734b 81.04%)',
  termsOfService:
    'https://storage.yandexcloud.net/terms-service-and-privacy-policy/Leenda/2024_LEENDA_Пользовательское%20соглашение_.pdf',
  privacyPolicy:
    'https://storage.yandexcloud.net/terms-service-and-privacy-policy/Leenda/2024_LEENDA_Политика%20обработки%20персональных%20данных.pdf',
  trialLink: 'mailto:support@lynda.ru',
  hasLmsIntegration: false,
  allowSignup: false,
  showPpAndTOS: false,
  authLogo,
  companyLogo,
  logo,
  logoFull,
  logoFull_M,
  courseHtmlFooter: `<b>Сделано в Lynda.</b> Платформа для создания образовательного контента.`,
  fallbackLng: 'ru',
}
